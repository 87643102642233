import axios from 'axios';
import config from '../config';
import moment from 'moment';
import {createAuth} from '../Helpers';

export const reducerName = 'plannedItemState';

const PLANS_GETALL_REQUEST = 'PLANS_GETALL_REQUEST';
const PLANS_GETALL_RESPONSE = 'PLANS_GETALL_RESPONSE';
const PLANS_SET_INTERVAL = 'PLANS_SET_INTERVAL';

const COLORS_GETALL_REQUEST = 'COLORS_GETALL_REQUEST';
const COLORS_GETALL_RESPONSE = 'COLORS_GETALL_RESPONSE';

const PLANS_CREATE_REQUEST = 'PLANS_CREATE_REQUEST';
const PLANS_CREATE_RESPONSE = 'PLANS_CREATE_RESPONSE';
const PLANS_CREATE_DOUBLE_BOOK = 'PLANS_CREATE_DOUBLE_BOOK';

const PLAN_SHOW_PLAN_FORM = 'PLAN_SHOW_PLAN_FORM';
const PLAN_RESET = 'PLAN_RESET';

const PLAN_SET_START_TIME = 'PLAN_SET_START_TIME';
const PLAN_SET_END_TIME = 'PLAN_SET_END_TIME';
const PLAN_SET_PERSON_ID = 'PLAN_SET_PERSON_ID';
const PLAN_SET_COLOR_CODE = 'PLAN_SET_COLOR_CODE';
const PLAN_SET_INVALID_START_TIME = 'PLAN_SET_INVALID_START_TIME';
const PLAN_SET_INVALID_END_TIME = 'PLAN_SET_INVALID_END_TIME';

const PLAN_SHOW_DELETE_BOX = 'PLAN_SHOW_DELETE_BOX';
const PLAN_DELETE_PLANNED_ITEM_FROM_STATE = 'PLAN_DELETE_PLANNED_ITEM_FROM_STATE';
const CLOSE_ALL_DELETE_BOXES = 'CLOSE_ALL_DELETE_BOXES';

const initialState = {
    plans: [],
    days: [],
    colorCodes: [],
    startTime: moment().startOf('day'),
    endTime: moment().startOf('day'),
    doubleBook: false,
    loading: {
        createPlannedItem: false,
        getAllColors: false,
        getAllPlans: false
    },
    plan:{
        personId: '',
        startTime: moment().set('hour', 7).set('minutes', 0),
        endTime: moment().set('hour', 15).set('minutes', 30),
        startValid: true,
        endValid: true,
        colorCode: null,
    },
    showPlanForm: false,
};



export const actionCreators = {
    getAllPlans: () => async(dispatch, getState) => {
        dispatch({type: PLANS_GETALL_REQUEST});

        const startTime = getState()[reducerName].startTime.format('YYYY-MM-DD');
        const endTime = getState()[reducerName].endTime.format('YYYY-MM-DD');

        const data = {startTime, endTime};

        axios({
            method: 'POST',
            url: `${config.server}/api/planneditem/search`,
            withCredentials: true,
            auth: createAuth(getState),
            data: data
        }).then(response => {
            const data = response.data;
            dispatch({type: PLANS_GETALL_RESPONSE, data: data, start:startTime, end: endTime});
        }).catch((data) =>{
            console.log('ERROR FETCHING PLANS', data);
        });
    },
    getAllColors: () => async(dispatch, getState) => {
        dispatch({type: COLORS_GETALL_REQUEST});

        const url = `${config.server}/api/colorcode`;

        axios({
            method: 'GET',
            url: url,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            const data = response.data;
            dispatch({type: COLORS_GETALL_RESPONSE, data: data});
        }).catch(error =>{
            console.log('ERROR FETCHING COLORS', error);
        });
    },
    createPlannedItem: (personId, colorCodeId, startTime, endTime) => async(dispatch, getState) => {
        dispatch({type: PLANS_CREATE_REQUEST});

        const url = `${config.server}/api/planneditem`;
        const data = { personId, colorCodeId, startTime, endTime };
        axios({
            method: 'POST',
            url: url,
            withCredentials: true,
            auth: createAuth(getState),
            data: data,
        }).then((response) => {
            const data = response.data;
            dispatch({type: PLANS_CREATE_RESPONSE, data: data, start:startTime, end: endTime});
            dispatch({type: PLAN_RESET});
            dispatch(actionCreators.setColorCode(colorCodeId));
        }).catch((error) => {
            console.log('ERROR CREATING PLAN', error);
            if(error.response.data === 'double_booking'){
                dispatch({type: PLANS_CREATE_DOUBLE_BOOK});
            }
        });
    },
    deletePlannedItem: (id) => async(dispatch, getState) => {
        // dvs. vi sletter det i staten - gør det kun hvis det er blevet successfuldt slettet i db
        //dispatch({type: PLAN_DELETE_PLANNED_ITEM, value: id});
        const url = `${config.server}/api/planneditem/${id}`;
        
        axios({
            method: 'DELETE',
            url: url,
            withCredentials: true,
            auth: createAuth(getState),
            data: {id: id}
        }).then((response) => {
            const data = response.data;
            dispatch({type: PLAN_DELETE_PLANNED_ITEM_FROM_STATE, data: data});
        }).catch((error) => {
            console.log(`her er en fejl ${error}`)
        });
    },
    setPersonId: (id) => async(dispatch, getState) => {
        dispatch({type: PLAN_SET_PERSON_ID, value: id});
    },
    setInterval: (start, end) => async(dispatch, getState) => {
        dispatch({type: PLANS_SET_INTERVAL, start, end});
    },
    setEndTime: (jsDate) => async(dispatch, getState) => {
        if(jsDate === 'Invalid date') {
            dispatch({type: PLAN_SET_INVALID_END_TIME, value: true});
        }
        else{
            dispatch({type: PLAN_SET_INVALID_END_TIME, value: false});
            dispatch({type: PLAN_SET_END_TIME, value:jsDate});
        }
    },
    setStartTime: (jsDate) => async(dispatch, getState) => {
        if(jsDate === 'Invalid date'){
            dispatch({type: PLAN_SET_INVALID_START_TIME, value: true});
        }
        else{
            dispatch({type: PLAN_SET_INVALID_START_TIME, value: false});
            dispatch({type: PLAN_SET_START_TIME, value:jsDate});
        }
    },
    setColorCode: (color) => async(dispatch, getState) => {
        dispatch({type: PLAN_SET_COLOR_CODE, value: color});
    },
    showPlanForm: (show) => async(dispatch, getState) => {
        dispatch({type: PLAN_SET_COLOR_CODE, value: null});
        dispatch({type: PLAN_SHOW_PLAN_FORM, show: show});
    },
    showDeleteBox: (id, show) => async(dispatch, getState) => {
        // hvis den skal vises skal alle de andre ikke vises
        if(show){
            dispatch({type: CLOSE_ALL_DELETE_BOXES, id: id});
        }
        dispatch({type: PLAN_SHOW_DELETE_BOX, id: id, show: show});
    }
};

const reducerMethods = {
    CLOSE_ALL_DELETE_BOXES: (state, action) => {

        const id = action.id;

        return{
            ...state,
            plans: state.plans.map(plan => {
                return plan.id !== id ? {...plan, showDelete: false} : {...plan}
            })
        }
    },
    PLAN_DELETE_PLANNED_ITEM_FROM_STATE: (state, action) => {
        const id = action.data.id;

        return {
            ...state, 
            plans: state.plans.filter(plan => plan.id !== id)
        }
    },    
    PLAN_SHOW_DELETE_BOX: (state, action) => {
        const id = action.id;
        const show = action.show;

        return{
            ...state,
            plans: state.plans.map(plan => {
                return plan.id === id ? {...plan, showDelete: show} : {...plan}
            })
        }
    },
    PLANS_SET_INTERVAL: (state, action) => {
        return{
            ...state,
            startTime: action.start,
            endTime: action.end
        }
    },
    PLAN_RESET: (state, action) => {
        const start = moment().set('hour', 7).set('minutes', 0);
        const end = moment().set('hour', 15).set('minutes', 30);
        return{
            ...state,
            plan: {
                ...initialState.plan,
                startTime: start,
                endTime: end,
            }
        }
    },
    PLAN_SHOW_PLAN_FORM: (state, action) => {
        return{
            ...state,
            showPlanForm: action.show,
            doubleBook: false,
        }
    },
    PLANS_GETALL_REQUEST: (state, action) => {
        return {
            ...state,
            loading:{
                getAllPlans: true
            }
        }
    },
    PLANS_GETALL_RESPONSE: (state, action) => {
        return{
            ...state,
            plans: action.data,
            loading: {
                ...state.loading,
                getAllPlans: false,
            }
        }
    },
    PLANS_CREATE_REQUEST: (state, action) => {
        return{
            ...state,
            doubleBook: false,
            loading:{
                ...state.loading,
                createPlannedItem: true
            }
        }
    },
    PLANS_CREATE_RESPONSE: (state, action) => {
        return{
            ...state,
            plans: [...state.plans, action.data],
            showPlanForm: false,
            loading: {
                ...state.loading,
                createPlannedItem: false
            }
        }
    },
    PLANS_CREATE_DOUBLE_BOOK: (state, action) => {
        return{
            ...state,
            doubleBook: true,
        }
    },
    COLORS_GETALL_REQUEST: (state, action) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                getAllColors: true,
            }
        }
    },
    COLORS_GETALL_RESPONSE: (state, action) => {
        return{
            ...state,
            colorCodes: action.data,
            loading:{
                ...state.loading,
                getAllColors: false
            }
        }
    },
    PLAN_SET_PERSON_ID: (state, action) => {
        return{
            ...state,
            plan:{
                ...state.plan,
                personId: action.value
            }
        }
    },
    PLAN_SET_COLOR_CODE: (state, action) => {
        return{
            ...state,
            plan:{
                ...state.plan,
                colorCode: action.value
            }
        }
    },
    PLAN_SET_START_TIME: (state, action) => {
        return{
            ...state,
            plan:{
                ...state.plan,
                startTime: action.value
            }
        }
    },
    PLAN_SET_END_TIME: (state, action) => {
        return{
            ...state,
            plan:{
                ...state.plan,
                endTime: action.value
            }
        }
    },
    PLAN_SET_INVALID_START_TIME: (state, action) => {
        return{
            ...state,
            plan:{
                ...state.plan,
                startValid: !action.value
            }
        }
    },
    PLAN_SET_INVALID_END_TIME: (state, action) => {
        return{
            ...state,
            plan:{
                ...state.plan,
                endValid: !action.value
            }
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};